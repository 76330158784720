<template>
  <div class="app-container">

    <a-card class="box-card" style="text-align: center">
      <a-form ref="config" :form="form" :layout="formLayout">
        <a-form-item
          label="发送人"
          :label-col="formItemLayout.labelCol"
          :wrapper-col="formItemLayout.wrapperCol">
          <a-input
            v-decorator="['send_name', {
              rules: [
                { required: true, message: '必填项，请填写信息' },
              ],
            }]" />
        </a-form-item>
        <a-form-item
          label="电话"
          :label-col="formItemLayout.labelCol"
          :wrapper-col="formItemLayout.wrapperCol">
          <a-input
            v-decorator="['send_mobile', {
              rules: [
                { required: true, message: '必填项，请填写信息' },
              ],
            }]" />
        </a-form-item>
        <a-form-item
          label="拣货单状态回调地址"
          :label-col="formItemLayout.labelCol"
          :wrapper-col="formItemLayout.wrapperCol">
          <a-input
            v-decorator="['pick_list_callback_url', {
              rules: [
                { required: false, message: '必填项，请填写信息' },
              ],
            }]" />
        </a-form-item>
        <a-form-item
          label="入库商品状态回调地址"
          :label-col="formItemLayout.labelCol"
          :wrapper-col="formItemLayout.wrapperCol">
          <a-input
            v-decorator="['commodity_purchase_callback_url', {
              rules: [
                { required: false, message: '必填项，请填写信息' },
              ],
            }]" />
        </a-form-item>
      </a-form>
      <div style="text-align: center;margin-top: 20px">
        <a-button type="primary" @click="handlePublish">提交</a-button>
      </div>
    </a-card>
  </div>
</template>
<script>
import { commonStoreConfig } from '@/api/c_wms_warehouse'
const pick = require('lodash.pick')
export default {
  name: 'ConfigCreate',
  data () {
    return {
      formLayout: 'horizontal',
      form: this.$form.createForm(this),
      dataSource: [],
      warehouseNormalList: [],
      config: {}
    }
  },
  computed: {
    formItemLayout () {
      const { formLayout } = this
      return formLayout === 'horizontal'
        ? {
          labelCol: { span: 6 },
          wrapperCol: { span: 14 }
        } : {}
    }
  },
  created () {
  },
  methods: {
    handlePublish () {
      this.form.validateFields((errors, values) => {
        if (!errors) {
          // 新增
          console.log(values)
          values.corporation_id = this.$Dictionaries.corporation_id
          commonStoreConfig(values).then(res => {
            console.log(res)
          })
        } else {
          console.log(errors)
        }
      })
      console.log(this.config)
    },
    setFormData () {
      new Promise((resolve) => {
        setTimeout(resolve, 0)
      }).then(() => {
        const formData = pick(this.config, ['e_warehouse_id', 'normal_warehouse_id', 'examine', 'oversold'])
        console.log('formData', formData)
        this.form.setFieldsValue(formData)
      })
    }
  }
}
</script>
<style>
</style>
